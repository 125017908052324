.navbar {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	height: 10vh;
	justify-content: flex-end;
	margin: 0 3rem; 
}

.switch {
	padding: 1rem 2rem ;
}
  
.navbarBtn {
	font-size: 2rem;
	font-weight: 700;
	padding: 0 3rem;
	position: relative; 
	transition: all 0.2s;
	color: inherit;
}
  
.navbarBtn:link, .navbarBtn:active, .navbarBtn:visited {
	text-decoration: none; 
}
  
.navbarBtn::after {
	bottom: -0.25rem;
	content: "";	
	height: 2px;
	left: 50%;
	position: absolute;
	transform: translate(-50%);
	transition: width 0.2s; 
	width: 0%;
}
  
.navbarBtn:hover {
	cursor: pointer;
}
	
.navbarBtn:hover::after {
	width: 50%; 
}