.btn {
	background-color: var(--color-dark);
	border-radius: 1.5rem;
	color: var(--color-light);
	display: inline-block;
	font-size: 3rem;
	font-weight: 800;
	overflow: hidden;
	padding: 1rem 4rem;
	position: relative;
	transition: all 0.2s;
	z-index: 1; 
}
  
.btn > :link,
.btn > :active,
.btn > :visited {
	color: var(--color-light);
	text-decoration: none;
	transition: color 0.65s;	
}
  
.btn::before {
	background-color: var(--color-light);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	transform: scaleY(0);
	transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s, background-color 0.1s;
	top: 0;
	width: 3px;
	z-index: -1; 
	background-image:  linear-gradient(-45deg,#1e34f7,#1ef792,#f71eec,#f7df1e);
}
  
.btn:hover {
	color: var(--color-light);
	cursor: pointer; 
	
}
  
.btn:hover::before {
	transform: scaleY(1);
	width: 100%; 
}
  
.btn:hover > :link,
.btn:hover > :active,
.btn:hover > :visited {
	color: var(--color-light);
	-webkit-text-stroke: 1px #000;
	transform: translateZ(10000); 
}
  