:root {
  --color-light: #f4f4f4;
  /* --color-dark: #333; */
  --color-dark: #000;
  /* --color-primary: #988345; */
  --color-primary: #fff; 
  --bg-dark: #1c1d25;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0; 

}

section {
  max-width: 200rem;
  margin: auto;
}

.textGradient {
  background-image: linear-gradient(-45deg,#1e34f7,#1ef792,#f71eec,#f7df1e);
  background-clip: text;
  color: transparent;
  transition: all 0.3s;
}  
  
#Dark {
  background-color: #000;
  color: #f4f4f4;
  fill: #f4f4f4;
  transition: all 0.3s;  
}

#Dark #resume-btn::after{
  background-color: #f4f4f4;
}

#Light {
  background-color: rgb(255, 255, 255);
  color: #000;
  fill: #000;
  transition: all 0.3s;
}

#Light #reactLogo, #nodeLogo, #nextLogo {
  filter: drop-shadow(1px 1px 2px black);  
}

#Light .textGradient {
  filter: drop-shadow(2px 2px 1px black);  
}

#Light #resume-btn::after{
  background-color: #f4f4f4;
}

::selection {
  color: #0a0a0c;
  background: #f0db4f; 
}

html {
  font-family: "Roboto", sans-serif; 
  font-size: 62.5%;   
}

@media only screen and (max-width: 68.75em) {
  html {
    font-size: 50%; 
  } 

  .container{
    max-width: none;
  }
  
}

@media only screen and (max-width: 31.25em) {

  .container{
    max-width: 100vw;
  }
  
}





