
.contact {
  position: relative; 
  padding-bottom: 30rem;
}

.content {
  align-items: center; 
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 40vw;
}

.title {
  font-size: 7rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
}

.mail {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 5rem;
  text-align: center;
}

@media only screen and (max-width: 68.75em) {

  .content {
    width: 90vw; 
  } 

  .mail {
    font-size: 2rem;
  }

  .contact {
    padding-bottom: 15rem;
  }
}

