  
.container {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: auto;
	text-align: center; 
  padding-bottom: 20rem; 
}
  
.text {
	font-size: 6rem;
	font-weight: 700;
  line-height: 7rem;
  padding: 0rem 5rem;
}

.logosGrid {
	display: flex;
  flex-wrap: wrap;
	justify-content: center;
	justify-items: center;
	padding: 10rem 5rem 20rem 5rem; 
  column-gap: 20rem;
  row-gap: 5rem;
}

.logo {
  height: 100px;
}


@media only screen and (max-width: 68.75em) {

  .text {
    font-size: 3.5rem;
    padding: 0rem 3rem;
    line-height: 1.2;
	} 

  .logo {
    height: 60px;
  }
  
}

@media only screen and (max-width: 56.25em) {
	
  .logosGrid {
    gap: 5rem;
    padding: 10rem 2rem 15rem 2rem; 
  }

  .logo {
    height: 40px;
  }

  .container {
    padding-bottom: 0rem; 
  }
}


  


